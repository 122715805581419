import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "react-lazy-load-image-component/src/effects/blur.css"

import Hero from "../components/hero"
import About from "../components/about"
import Services from "../components/services"
import Reviews from "../components/reviews"

import LogoWhite from "../images/logo-white.png"
import WebriQForm from "../components/form/form"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout
        location={this.props.location}
        title="Healthcare Reimbursement and Policy Consulting"
      >
        <SEO
          title="Epiphron"
          description="Healthcare Reimbursement and Policy Consulting"
        />
        <Hero />
        <About />
        <Services />

        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-6 system-int-content">
              <h2 className="mt-0">System Integration</h2>
              <p>
                At EPIPHRON we pride ourselves on assisting you from the ground
                up. Our team has the ability to identify what your system needs
                to function successfully in the health care marketplace. We will
                work together, with you, on pinpointing possible changes to your
                systems that will safeguard your processing and assist in
                smoother work stream.{" "}
              </p>
            </div>
            <div className="col-md-6 system-int-img"></div>
          </div>
        </div>

        {/* <Reviews /> */}
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
