import React from "react"

import svcImg1 from "../images/svc-img-2.jpeg"
import svcImg2 from "../images/svc-img-1.jpeg"

const Services = ({ location }) => (
  <div className="container-fluid svc-section padding-200" id="services">
    <div className="container">
      <h2 className="mb-5 mt-0 text-center text-white">What We Offer</h2>
      <div className="row svc-row">
        <div className="col-md-6 padding-top-30">
          <div className="svc-container shadow-sm">
            <div className="svc-img-2">
              <img
                className="h-100 w-100 svc-img"
                src={svcImg1}
                alt="Healthcare Reimbursement"
              />
            </div>
            <div className="svc-content">
              <h2 className="mt-0">Healthcare Reimbursement</h2>
              <p className="mb-4">
                Let EPIPHRON help to take the guess work out of regulations and
                reimbursement from Federal and Private healthcare payers. Our
                team has decades of experience working in and around
                reimbursement methodologies and in many cases, have written the
                very policy you may need assistance with. EPIPHRON will assist
                in planning for your bottom line due to unexpected costs and
                potential reimbursement changes.
              </p>
              {/* <button className="btn-global hvr-outline-out" type="button">
                Learn More
              </button> */}
            </div>
          </div>
        </div>
        <div className="col-md-6 padding-top-30">
          <div className="svc-container shadow-sm">
            <div className="svc-img-1">
              <img
                className="w-100 svc-img"
                src={svcImg2}
                alt="Healthcare Policy Consultation"
              />
            </div>
            <div className="svc-content">
              <h2 className="mt-0">Healthcare Policy Consultation</h2>
              <p className="mb-4">
                The market for health care procedures and technologies is
                growing every day. Let our highly experienced team provide
                comprehensive health care policy development and further
                guidance on existing policies.
              </p>
              {/* <button className="btn-global hvr-outline-out" type="button">
                Learn More
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Services
