import React from "react"
import { Link } from "gatsby"

const Hero = ({ location }) => (
  <div className="container-fluid hero-section vertical-align">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="brandstamp text-center text-white">
            <h1 className="mb-5 mt-5">
              <strong>
                Expert guidance on Government and Industry health care changes
              </strong>
            </h1>
            {/*} <a
              className="btn-global hvr-outline-out-main main-cta"
              type="button"
              href="tel:720-346-8999"
            >
              <i className="fa fa-phone mr-2"></i>Call Us Today
            </a> */}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Hero
