import React from "react"

import aboutImg from "../images/about-img2.jpeg"

const About = ({ location }) => (
  <div id="about">
    <div className="container-fluid about-section">
      <div className="container">
        <div className="row about-row">
          <div className="col-lg-6 padding-top-30">
            <img
              className="w-100 about-img shadow-sm"
              src={aboutImg}
              alt="About Epiphron"
            />
          </div>
          <div className="col-lg-6 padding-top-30 vertical-align">
            <div className="about-content">
              <h2 className="mt-0">
                About&nbsp;<span className="primary-clr">EPIPHRON</span>
              </h2>
              <h5>
                <span className="primary-clr">EPIPHRON&nbsp;</span>
                provides all levels of health care consulting with a focus on
                reimbursement, policy and system integration. With decades of
                Federal and Private industry experience, we have the expertise
                to guide you through the ever changing health care environment.
                Our team can assist in assuring federal and state level
                compliance as well as policy and reimbursement development. Let{" "}
                <span className="primary-clr">EPIPHRON&nbsp;</span>
                take you and your business to a higher level of thinking.
              </h5>
              {/* <button className="btn-global hvr-outline-out" type="button">
              Read More
            </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default About
